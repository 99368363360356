<template>
    <div>
        <!-- <TrailerOrder_Component></TrailerOrder_Component> -->
        <router-view></router-view>
    </div>
</template>

<script>
import TrailerOrder_Component from '../../components/PersonalCenter/TrailerOrder_Component.vue';
export default {
    name: "TrailerOrder",
    components: { TrailerOrder_Component }
}
</script>

<style lang="scss" scoped>

</style>